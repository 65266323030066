.finish_container
  background-color: #fff
  width: 100vw
  height: 100vh
  display: flex
  flex-direction: column
  align-items: center
  position: fixed
  left: 0
  top: 0

.finish_heading
  width: 100%
  border-bottom: 1px solid $yellow
  @include mobile
    width: calc(100% - 1.33rem)
    margin: 0 0.67rem

.finish_title
  @include fontSWCL(3rem, bold, #000, 3.67rem)
  margin-top: 4rem
  margin-bottom: 1.67rem
  text-align: center
  @include mobile
    font-size: 1.5rem
    line-height: 1.8rem
    margin-top: 6rem
    margin-bottom: 0.83rem

.finish_body
  width: 100%
  display: flex
  flex-direction: column
  flex-grow: 1
  justify-content: center
  align-items: center
  padding: 0 10%
  @include mobile
    padding: 0 0.83rem

.finish_message
  @include fontSWCL(2rem, normal, #000, 2.5rem)
  @include mobile
    font-size: 1.33rem
    line-height: 1.67rem

.finish_button
  width: 23rem
  background-color: $yellow
  border-radius: 3.25rem
  display: flex
  justify-content: center
  align-items: center
  padding: 1.75rem 9rem
  cursor: pointer
  @include mobile
    width: 45%
    border-radius: 2rem
    padding: 1.7rem 5rem

  &_text
    @include fontSWCL(1.5rem, normal, #fff, 1.75rem)
    font-family: Roboto
    @include mobile
      font-size: 1.2rem
      line-height: 1.6rem
