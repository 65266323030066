.driver_container
  display: flex
  flex-direction: row
  flex-wrap: wrap
  justify-content: flex-start
  align-items: flex-start
  margin: 0 2.5rem
  @include mobile
    margin: 0 0.67rem
    align-items: flex-end

  &:first-child
    @include mobile
      margin-top: 1.67rem

  &_item
    margin-top: 1.67rem
    margin-right: auto
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: flex-start
    @include mobile
      width: 100%
      margin-top: 0.83rem

    &-center
      align-items: center

    &-short
      @include mobile
        width: 47%

    &:last-child
      margin-right: 0

.driver_subtitle
  margin-top: 1.67rem
  width: 100%
  @include fontSWCL(1.5rem, bold, #000, 1.75rem)
  @include mobile
    text-align: center
    font-size: 1rem
    line-height: 1.25rem
    font-weight: normal

.driver_subcontainer
  display: flex
  flex-direction: row
  margin-right: 0.83rem
  @include mobile
    flex-wrap: wrap
    width: 100%
    margin-right: 0

  &:last-child
    margin-right: 0

  & > input, & > .dadata
    margin-right: 0.83rem
    @include mobile
      margin-right: 1.67rem

    &:last-child
      margin-right: 0

.driver_phone
  display: flex
  flex-direction: column
  align-items: flex-start
  margin-right: 0.83rem
  position: relative
  @include mobile
    width: 100%
    margin-right: 0

  &:last-child
    margin-right: 0

  &_controls
    position: absolute
    right: 1rem
    top: 1.75rem
    width: 3.33rem
    height: 1rem
    display: flex
    flex-direction: row
    justify-content: flex-end
    @include mobile
      top: 1.4rem

  &_add
    @include fontSWCL(0.83rem, normal, $yellow, 1rem)
    font-family: Roboto
    text-decoration: underline
    cursor: pointer

  &_add_mobile
    position: relative
    cursor: pointer
    width: 1.33rem
    height: 1.33rem
    background-color: $yellow
    border-radius: 50%

    &::after
      position: absolute
      left: 0
      top: 0
      content: '+'
      width: 1.33rem
      height: 1.33rem
      @include fontSWCL(2rem, normal, #fff, 1.33rem)
      text-align: center

  &_remove
    position: relative
    cursor: pointer
    width: 1.33rem
    height: 1.33rem
    margin-left: 0.5rem

    &::after
      position: absolute
      width: 1rem
      height: 1rem
      left: 0
      top: 0
      content: url("data:image/svg+xml,%3Csvg width='1rem' height='1rem' viewBox='0 0 448 512' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16zM53.2 467a48 48 0 0 0 47.9 45h245.8a48 48 0 0 0 47.9-45L416 128H32z' fill='%23FFB700'/%3E%3C/svg%3E%0A")

.driver_dadata
  margin-top: 0.83rem
  width: 32rem
  @include mobile
    width: 100%
    margin-top: 0.42rem

  &_nomargin
    @include mobile
      margin-right: 0!important

  &-long
    flex-grow: 1
    width: 16rem
    @include mobile
      width: 44%

  &-short
    width: 5rem
    @include mobile
      width: 20%

  &_input
    padding: 1rem
    width: 32rem
    border: 1px solid #999
    border-radius: 0.33rem
    @include fontSWCL(1.2rem, normal, #000, 1.33rem)
    font-family: Roboto
    @include mobile
      padding: 1rem 0.83rem
      width: 100%

.driver_input
  margin-top: 0.83rem
  padding: 1rem
  width: 20rem
  max-width: 100%
  border: 1px solid #999
  border-radius: 0.33rem
  @include fontSWCL(1.2rem, normal, #000, 1.33rem)
  font-family: Roboto
  @include mobile
    width: 100%
    margin-top: 0.42rem
    padding: 1rem 0.83rem

  &-normal
    width: 12.5rem
    @include mobile
      width: 100%

  &-long
    width: 90%
    @include mobile
      width: 100%!important

  &-short
    width: 5rem
    @include mobile
      width: 20%

  &_license
    width: 12.5rem
    @include mobile
      width: 100%

  &::placeholder
    color: #bdbdbd

  &-complete
    border: 1px solid $yellow

  &_label
    @include fontSWCL(1.33rem, normal, #000, 1.6rem)
    @include mobile
      font-size: 1.2rem
      line-height: 1.4rem

    &::after
      @include mobile
        content: '*'
        font-size: 1.2rem
        line-height: 1.4rem

.driver_checkbox_container
  cursor: pointer

.driver_checkbox
  width: 2.5rem
  @include mobile
    width: 3rem

  &-info
    width: 1.67rem
    @include mobile
      width: 1.25rem

    &_sign
      width: 1.25rem
      height: 1.25rem
      border: 1px solid $yellow
      border-radius: 50%
      @include fontSWCL(1rem, normal, $yellow, 1.25rem)
      text-align: center
      @include mobile
        width: 0.83rem
        height: 0.83rem
        font-size: 0.6rem
        line-height: 0.83rem

.driver_checkbox_label
  @include fontSWCL(1.33rem, normal, #000, 1.67rem)
  @include mobile
    font-size: 1rem
    line-height: 1.25rem

  &-info
    @include fontSWCL(1.33rem, normal, #000, 1.67rem)
    @include mobile
      font-size: 0.83rem
      line-height: 1rem
      text-decoration: underline

.driver_photo_container
  display: flex
  flex-direction: row
  justify-content: space-around
  align-items: flex-start
  width: 100%

.driver_photo
  margin-top: 0.83rem
  display: flex
  flex-direction: column
  align-items: center
  justify-content: flex-start
  @include mobile
    margin-right: 0

  &:last-child
    margin-right: 0

  & input
    display: none

  &_subcontainer
    display: flex
    flex-direction: row
    width: 24rem
    justify-content: space-around

  &_label
    @include fontSWCL(1.33rem, normal, #000, 1.6rem)
    width: 100%
    text-align: center
    @include mobile
      font-size: 1rem
      line-height: 1.25rem

  &_button
    width: 10rem
    height: 3.33rem
    margin: 0.42rem 0
    cursor: pointer
    outline: none
    background: $yellow
    border-radius: 2.08rem
    display: flex
    justify-content: center
    align-items: center
    @include mobile
      width: 8rem

    &_text
      @include fontSWCL(1.2rem, normal, #fff, 1.33rem)
      font-family: Roboto
      text-align: center

    &-complete &_text
      font-weight: bold

    &_snap
      margin-top: 0.83rem
      @include mobile
        width: 12rem
        position: absolute
        bottom: 2.5rem

.driver_video_container
  margin-top: 0.83rem
  display: flex
  flex-direction: column
  align-items: center
  justify-content: flex-start
  width: 50%
  @include mobile
    position: fixed
    left: 0
    top: 0
    width: 100%
    height: 100%
    background-color: #fff
    z-index: 10

.driver_video
  width: 100%
  height: 100%

.driver_category_container
  display: flex
  flex-direction: row
  flex-wrap: wrap
  width: 100%

.driver_category_item
  display: flex
  flex-direction: row
  flex-wrap: nowrap
  width: 25%
  margin-top: 0.42rem

.driver_category_check
  margin-right: 0.83rem

.driver_category_label
  width: 5.8rem
  @include fontSWCL(1.33rem, normal, #000, 1.6rem)
  @include mobile
    font-size: 1rem
    line-height: 1.25rem

.driver_geo
  @include fontSWCL(1.2rem, normal, $yellow, 1.4rem)
  font-family: Roboto
  text-decoration: underline
  cursor: pointer
  margin-top: 0.33rem
  margin-left: 0.2rem
