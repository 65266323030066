@import "../sass/settings/color"
@import "../sass/tools/mixin"
@import "../sass/tools/media"

.checkbox
  position: relative
  width: 1.2rem
  height: 1.2rem
  cursor: pointer

  & > input
    position: absolute
    width: 100%
    height: 100%
    left: 0
    top: 0
    opacity: 0

.checkbox_mark
  position: absolute
  width: 100%
  height: 100%
  left: 0
  top: 0
  border: 1px solid $yellow

.checkbox > input:checked ~ .checkbox_mark
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAABmJLR0QA/wD/AP+gvaeTAAAAgElEQVRIie2UQQqAIBBF3yWKuv9JWkVYm1x0nFooIRWhpQPRPHCnPmb8IyiK8owamIBOWmqBFRikpBUwe+kCNCpV6X+kPTDiZi+WcE5t4tkdk3hBFimcW9Ym7H39pjHyYkG6kxdP75VcbGSO4ckSpFjCKkU/B3DVGb+KV6oo32QDDxpD3wtiIfoAAAAASUVORK5CYII=")
  background-size: cover

.radio
  position: relative
  width: 1.5rem
  height: 1.5rem
  border: 1px solid $yellow
  border-radius: 50%
  cursor: pointer

  & > input
    position: absolute
    width: 100%
    height: 100%
    left: 0
    top: 0
    opacity: 0
    cursor: pointer

.radio_button
  position: absolute
  left: 50%
  top: 50%
  -webkit-transform: translate(-50%, -50%)
  transform: translate(-50%, -50%)
  width: 1rem
  height: 1rem
  border-radius: 50%
  background-color: $yellow
  display: none

.radio > input:checked ~ .radio_button
  display: block

.loader
  position: absolute
  left: 50%
  top: 50%
  -webkit-transform: translate(-50%, -50%)
  transform: translate(-50%, -50%)

.react-dadata__suggestions
  position: absolute
  top: calc(100% - 1rem)
  left: 0
  display: flex
  flex-direction: column
  align-items: flex-start
  width: 100%
  background-color: #fff
  border-right: 1px solid #999
  border-left: 1px solid #999
  border-bottom: 1px solid #999
  border-radius: 0 0 0.33rem 0.33rem

.react-dadata__suggestion
  width: 100%
  text-align: left
  border: none
  box-shadow: none
  outline: none
  background-color: transparent
  @include fontSWCL(1.33rem, normal, #000, 1.67rem)
  padding: 0.33rem 1.33rem
  border-bottom: 1px solid #999
  @include mobile
    font-size: 1.2rem
    line-height: 1.4rem

  &:last-child
    border-bottom: none

  &:hover
    background-color: rgba(0, 0, 0, 0.1)

.react-dadata
  width: 100%
  position: relative
  z-index: 1

.hidden
  display: none

.desktop_only
  @include mobile
    display: none!important

.mobile_only
  display: none!important
  @include mobile
    display: block!important

.mobile_right
  @include mobile
    margin-right: 0!important

.message_window
  position: fixed
  display: flex
  justify-content: center
  align-items: center
  left: 0
  top: 0
  width: 100vw
  height: 100vh
  z-index: 10
  background-color: rgba(0, 0, 0, 0.3)

.message
  display: flex
  justify-content: center
  align-items: center
  padding: 2rem
  margin: 2rem
  background-color: #fff
  border-radius: 0.33rem

  &_text
    @include fontSWCL(1.33rem, bold, #000, 1.67rem)
    text-align: center
    @include mobile
      font-size: 1.2rem
      line-height: 1.4rem

.active_tab
  display: flex
  flex-direction: column
  justify-content: flex-start
  align-items: flex-start
  width: 100%
  position: absolute
  left: -0.8rem
  top: -3.2rem
  margin: 3rem 0.8rem 0 0.8rem
  z-index: 1
  @include mobile
    position: fixed
    width: 100%
    height: calc(100% + 3rem)
    left: -0.8rem
    top: -3rem
    background-color: #fff
    overflow: auto
    margin-top: 0

  &_modal
    position: fixed
    width: 100%
    height: 100%
    left: 0
    top: 0
    background-color: transparent

  &_heading
    width: 100%
    height: 8rem
    padding: 0.8rem
    border-width: 5px 5px 0px 5px
    border-style: solid
    border-color: $yellow
    border-radius: 0.33rem 0.33rem 0 0
    background-color: #fff
    display: flex
    flex-direction: column
    justify-content: center
    align-items: flex-start
    position: relative
    @include mobile
      width: calc(100% - 1.33rem)
      margin: 0 0.67rem
      border-width: 0 0 1px 0
      border-radius: 0
      align-items: center
      justify-content: flex-start
      height: auto

    &::after
      width: 100%
      position: absolute
      left: 0
      bottom: 0
      content: ''
      height: 5px
      background-color: #fff
      z-index: 1
      @include mobile
        display: none

  &_title
    @include fontSWCL(2rem, normal, #000, 2.4rem)
    @include mobile
      font-size: 1.5rem
      font-weight: bold
      line-height: 1.8rem
      margin-top: 4rem
      margin-bottom: 0.83rem

  &_body
    width: calc(200% + 1.6rem)
    border: 5px solid $yellow
    border-radius: 0.33rem
    position: relative
    background-color: #fff
    position: relative
    left: 0
    top: -5px
    padding: 2.5rem
    @include mobile
      width: 100%
      border: none
      top: 0
      padding: 0

  &_button
    margin: 4rem auto 0 auto
    width: 18rem
    display: flex
    justify-content: center
    align-items: center
    padding: 1.25rem 5.4rem
    border: 1px solid $yellow
    border-radius: 2rem
    cursor: pointer
    background-color: $yellow
    @include mobile
      width: 10rem
      padding: 0.83rem 2rem

    &-inactive
      background-color: #fff

      & .active_tab_button_text
        color: $yellow

    &_text
      @include fontSWCL(1.5rem, normal, #fff, 1.75rem)
      font-family: Roboto
      text-align: center
      @include mobile
        font-size: 1.2rem
        line-height: 1.33rem

  &_back
    margin: 1.67rem auto
    width: 5rem
    @include fontSWCL(1.5rem, normal, $yellow, 1.75rem)
    font-family: Roboto
    text-align: center
    cursor: pointer
    @include mobile
      font-size: 1.2rem
      line-height: 1.33rem

.select
  position: relative
  margin-top: 0.83rem
  width: 20rem
  max-width: 100%
  @include mobile
    width: 100%
    margin-top: 0.42rem

  &:focus
    outline: none

  & > input
    cursor: pointer
    width: 100%
    overflow: hidden
    text-overflow: ellipsis
    padding-right: 2.5rem
    border: 1px solid #999
    border-radius: 0.33rem

  &_dropdown
    position: absolute
    left: 0
    top: calc(100% + 5px)
    width: 100%
    border: 1px solid #bdbdbd
    border-radius: 0.33rem
    background-color: #f1f1f1
    z-index: 10
    display: none

    &-open
      display: unset

    &_handle
      position: absolute
      right: 0.83rem
      top: 0.83rem
      width: 1.67rem
      height: 1.67rem
      cursor: pointer

      &::after
        position: absolute
        left: 0
        top: 0
        width: 100%
        height: 100%
        -webkit-transition: -webkit-transform 0.1s
        transition: transform 0.1s
        content: url("data:image/svg+xml,%3Csvg width='1.67rem' height='1.67rem' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 5L6 10L13 5' stroke='%23FFB700' stroke-width='2'/%3E%3C/svg%3E%0A")

    &-open ~ &_handle::after
      -webkit-transform: rotate(180deg)
      transform: rotate(180deg)

    &_item
      cursor: pointer
      width: 100%

      &-lined
        border-bottom: 1px solid #bdbdbd

        &:last-child
          border-bottom: none

      &:hover, &:focus, &:focus-within, &:active
        background: #ccc
        outline: none

      &_text
        @include fontSWCL(1rem, normal, #000, 2.5rem)
        font-family: Roboto
        margin: 0 0.42rem

      &_label
        @include fontSWCL(1rem, normal, #000, 2.5rem)
        font-family: Roboto
        margin: 0 0.42rem

      &_input
        @include fontSWCL(1rem, normal, #000, 2.5rem)
        font-family: Roboto
        margin: 0 0.42rem 0.42rem 0.42rem
        padding: 0 0.42rem
        width: calc(100% - 0.83rem)
        border: 1px solid #bdbdbd
        border-radius: 0.33rem

    &_nodata
      @include fontSWCL(1rem, normal, #000, 3rem)
      font-family: Roboto
      text-align: center

  &_loader
    position: absolute
    left: 50%
    top: 50%
    -webkit-transform: translate(-50%, -50%)
    transform: translate(-50%, -50%)

.dadata
  position: relative

.dadata_suggestions
  position: absolute
  z-index: 1
  top: calc(100% + 0.1rem)
  left: 0
  display: flex
  flex-direction: column
  align-items: flex-start
  width: 100%
  background-color: #fff
  border: 1px solid #999
  border-radius: 0.33rem

.dadata_suggestion
  width: 100%
  text-align: left
  border: none
  box-shadow: none
  outline: none
  background-color: transparent
  @include fontSWCL(1.2rem, normal, #000, 1.33rem)
  font-family: Roboto
  padding: 0.33rem 1rem
  border-bottom: 1px solid #999
  cursor: pointer
  @include mobile
    font-size: 1.2rem

  &:last-child
    border-bottom: none

  &:hover
    background-color: rgba(0, 0, 0, 0.1)

.camera_wait
  width: 100%
  height: 20rem
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  text-align: center
  @include fontSWCL(1.33rem, normal, #000, 1.67rem)
  border: 1px solid #999
  border-radius: 0.33rem

.captcha
  margin-top: 1.67rem

.react-date-picker
  margin-top: 0.83rem
  width: 20rem
  height: calc(3.334rem + 3px)
  max-width: 100%
  border: 1px solid #999
  border-radius: 0.33rem
  @include fontSWCL(1.2rem, normal, #000, 1.33rem)
  font-family: Roboto
  @include mobile
    width: 100%
    margin-top: 0.42rem

  & *
    @include fontSWCL(1.2rem, normal, #000, 1.33rem)
    font-family: Roboto

  &__wrapper
    border: none!important

  &__inputGroup
    padding: 1rem!important
    @include mobile
      padding: 1rem 0.83rem
