@import "./settings/color"
@import "./settings/font"
@import "./tools/mixin"
@import "./tools/media"

html
  font-size: 12px

*, *::before, *::after
  margin: 0
  padding: 0
  border: none
  color: #000
  font-size: 1rem
  vertical-align: baseline
  list-style: none
  text-decoration: none
  font-family: $font-family
  font-weight: normal

a
  outline: none

button, input
  &:focus, &:focus-within
    outline: none

::placeholder
  font-family: Roboto
  font-weight: 300
  color: #999

input::-webkit-outer-spin-button, input::-webkit-inner-spin-button
  -webkit-appearance: none
  margin: 0

input[type='date']
  height: 3.4rem

//......................................Elements.............................................

@import "./common-elements"
