.referrer_container
  display: flex
  flex-direction: row
  flex-wrap: wrap
  justify-content: flex-start
  align-items: flex-start
  margin: 0 2.5rem
  @include mobile
    margin: 0 0.67rem

  &:first-child
    @include mobile
      margin-top: 1.67rem

  &_item
    margin-top: 1.67rem
    margin-right: auto
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: flex-start
    max-width: 32%
    @include mobile
      width: 100%
      max-width: 100%
      margin-top: 0.83rem

    &-center
      align-items: center

    &-short
      @include mobile
        width: 47%

    &:last-child
      margin-right: 0

.referrer_title
  margin: 0 auto 2.5rem auto
  @include fontSWCL(1.2rem, normal, #000, 1.4rem)

.referrer_subcontainer
  display: flex
  flex-direction: row
  width: 100%
  justify-content: space-between
  position: relative
  @include mobile
    flex-wrap: wrap
    margin-top: 0.83rem

  &:first-child
    @include mobile
      margin-top: 0

.referrer_input
  margin-top: 0.83rem
  padding: 1rem
  width: 20rem
  max-width: 100%
  border: 1px solid #999
  border-radius: 0.33rem
  @include fontSWCL(1.2rem, normal, #000, 1.33rem)
  font-family: Roboto
  @include mobile
    width: 100%
    margin-top: 0.42rem
    padding: 1rem 0.83rem

  &::placeholder
    color: #bdbdbd

  &-complete
    border: 1px solid $yellow

  &_label
    @include fontSWCL(1.33rem, normal, #000, 1.6rem)
    @include mobile
      font-size: 1.2rem
      line-height: 1.4rem

    &::after
      @include mobile
        content: '*'
        font-size: 1.2rem
        line-height: 1.4rem

.referrer_add
  margin: 4rem auto 1.67rem auto
  @include fontSWCL(1.5rem, normal, $yellow, 1.75rem)
  font-family: Roboto
  text-align: center
  text-decoration: underline
  cursor: pointer
  @include mobile
    font-size: 1.2rem
    line-height: 1.33rem

.referrer_remove
  align-self: flex-end
  bottom: 1.33rem
  width: 1rem
  height: 1rem
  cursor: pointer
  position: relative
  @include mobile
    position: absolute
    right: 0.33rem
    top: 0.83rem

  &::after
    position: absolute
    width: 1rem
    height: 1rem
    left: 0
    top: 0
    content: url("data:image/svg+xml,%3Csvg width='1rem' height='1rem' viewBox='0 0 448 512' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16zM53.2 467a48 48 0 0 0 47.9 45h245.8a48 48 0 0 0 47.9-45L416 128H32z' fill='%23FFB700'/%3E%3C/svg%3E%0A")
