@import "../settings/color"
@import "../tools/mixin"
@import "../tools/media"

.phone_section
  width: 100%
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center

  &-hidden
    @include mobile
      display: none

.code_section
  width: 100%
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  margin-top: 2.5rem
  @include mobile
    margin-top: 0
    margin-bottom: 50%

.phone_subtitle
  @include fontSWCL(2rem, normal, #000, 2.4rem)
  text-align: center
  @include mobile
    font-size: 1.33rem
    line-height: 1.67rem

.phone_description
  @include fontSWCL(1.33rem, normal, #000, 1.67rem)
  text-align: center
  margin-top: 1.67rem
  @include mobile
    font-size: 0.67rem
    line-height: 0.83rem

.phone_input_container
  width: 28%
  height: 5.67rem
  display: flex
  justify-content: center
  align-items: center
  border: 1px solid #999
  border-radius: 0.33rem
  margin: 1.67rem auto
  @include mobile
    width: 64%
    height: 3.33rem

  &-complete
    border: 1px solid $yellow

  &-disabled
    cursor: not-allowed

.phone_input_container_short
  width: 12%
  height: 5.67rem
  display: flex
  justify-content: center
  align-items: center
  border: 1px solid #999
  border-radius: 0.33rem
  margin: 1.67rem auto
  position: relative
  @include mobile
    width: 45%
    height: 3.33rem
    margin-top: 6.67rem

  &-complete
    border: 1px solid $yellow

  &-disabled
    cursor: not-allowed

.phone_input
  @include fontSWCL(1.5rem, normal, #000, 5.67rem)
  font-family: Roboto
  text-align: center
  width: 100%
  background-color: transparent
  @include mobile
    font-size: 1rem
    line-height: 3.33rem

.code_input
  @include fontSWCL(1.5rem, normal, #000, 5.67rem)
  font-family: Roboto
  letter-spacing: 0.5em
  text-align: center
  width: 100%
  background-color: transparent
  @include mobile
    font-size: 1rem
    line-height: 3.33rem

.phone_agree_section
  cursor: pointer

.phone_agree_checkbox
  min-width: 2.6rem

.phone_agree_text
  @include fontSWCL(1.33rem, normal, #000, 1.67rem)
  max-width: 17rem
  @include mobile
    font-size: 1rem
    line-height: 1.25rem

  & > a
    @include fontSWCL(1.33rem, normal, $yellow, 1.67rem)
    text-decoration: underline
    @include mobile
      font-size: 1rem
      line-height: 1.25rem

.phone_button
  display: flex
  justify-content: center
  align-items: center
  margin: 2rem 0
  cursor: pointer
  border: 1px solid $yellow
  border-radius: 3.25rem
  position: relative

  &_text
    @include fontSWCL(1.5rem, normal, $yellow, 1.75rem)
    font-family: Roboto
    margin: 1.75rem 4.2rem

  &-active
    background-color: $yellow

    & .phone_button_text
      color: #fff

.partner_header
  width: 100%
  max-width: 96rem
  display: flex
  flex-direction: column
  justify-content: flex-start
  align-items: center

.partner_type_container
  width: 100%
  display: flex
  flex-direction: row
  justify-content: center
  margin-top: 3rem

.partner_type_button
  width: 40%
  border: 1px solid $yellow
  border-radius: 0.33rem
  padding: 1.33rem 2rem
  margin: 0 0.8rem
  cursor: pointer
  display: flex
  align-items: center
  justify-content: center
  @include mobile
    width: 45%
    padding: 0.4rem 2rem

  &-selected
    background: $yellow

  &_text
    @include fontSWCL(2rem, normal, #000, 2.4rem)
    width: 100%
    text-align: center
    @include mobile
      font-size: 1.33rem
      line-height: 1.67rem

  &-selected &_text
    color: #fff

.partner_tin_container
  width: 100%
  margin-top: 3rem
  display: flex
  flex-direction: column
  align-items: center
  justify-content: flex-start
  @include mobile
    margin-top: 1.67rem

.partner_tin
  display: flex
  flex-direction: column
  width: 38%
  align-items: flex-start
  @include mobile
    width: 96%

  &_label
    @include fontSWCL(1.33rem, normal, #000, 1.67rem)
    @include mobile
      font-size: 1.2rem
      line-height: 1.4rem

  &_input
    width: 100%
    margin: 0.8rem 0
    padding: 1.67rem 4.2rem
    border: 1px solid #999
    border-radius: 0.33rem
    @include fontSWCL(1.5rem, 500, #000, 1.75rem)
    font-family: Roboto
    letter-spacing: 0.2em
    @include mobile
      padding: 1rem auto
      font-size: 1.2rem
      line-height: 1.4rem
      text-align: center

    &-complete
      border: 1px solid $yellow

    &::placeholder
      letter-spacing: normal

.partner_tin_later
  cursor: pointer
  @include mobile
    width: 65%

  &_checkbox
    min-width: 2.6rem

  &_text
    max-width: 55%
    @include fontSWCL(1.33rem, normal, #000, 1.67rem)
    @include mobile
      font-size: 1rem
      line-height: 1.25rem

.partner_radio
  display: flex
  flex-direction: row

  & > span
    display: flex
    flex-direction: row
    cursor: pointer

  &_label
    @include fontSWCL(1.33rem, normal, #000, 1.67rem)
    margin-left: 1.25rem
    margin-right: 3rem
    @include mobile
      font-size: 1.2rem
      line-height: 1.4rem
      margin-left: 1.33rem
      margin-right: 2.75rem

.partner_footer
  display: block
  padding-top: 40px

  .partner_footer_container
    display: flex
    justify-content: center
    align-items: center
    flex-direction: column

.partner_save
  display: flex
  justify-content: center
  align-items: center
  margin-top: auto
  margin-bottom: 4.2rem
  padding: 1.17rem 5.5rem
  background-color: $yellow
  border-radius: 2rem
  cursor: pointer
  @include mobile
    margin-bottom: 1.67rem
    padding: 0.83rem 2.1rem

  &-inactive
    border: 1px solid $yellow
    background-color: #fff

    & .partner_save_text
      color: $yellow
    & .partner_exit
      color: $yellow
  &_text
    @include fontSWCL(1.5rem, normal, #fff, 1.75rem)
    width: 100%
    text-align: center
    @include mobile
      font-size: 1.2rem
      line-height: 1.33rem

.partner_exit
  background: #fff
  border: 2px solid #ffb700

.error
  border-width: 3px!important
  border-style: solid
  border-color: crimson!important
  border-radius: 0.33rem

.partner_body
  display: flex
  flex-direction: row
  flex-wrap: wrap
  justify-content: flex-start
  align-items: flex-start
  width: 78%
  max-width: 96rem
  margin-bottom: 1.67rem
  @include tablet
    width: 90%
  @include mobile
    width: 100%
    justify-content: space-between

.partner_tab_container
  position: relative
  width: 47%
  margin: 3rem 0.8rem 0 0.8rem
  display: flex
  flex-direction: column
  @include mobile
    width: 96%
    margin: 1.67rem 2% 0 2%

.partner_tab
  width: 100%
  height: 8rem
  padding: 0.4rem 0.8rem
  border: 1px solid $yellow
  border-radius: 0.33rem
  cursor: pointer
  display: flex
  flex-direction: column
  justify-content: center
  align-items: flex-start
  position: relative
  background-color: #fff
  overflow: auto
  @include mobile
    height: 6.75rem
    justify-content: flex-start
    align-items: center
    padding: 0.4rem 1.6rem

  &::after
    @include mobile
      position: absolute
      right: 50%
      bottom: 0.4rem
      -webkit-transform: translateX(50%)
      transform: translateX(50%)
      width: 3.33rem
      height: 3.33rem
      background-color: $yellow
      border-radius: 50%
      content: '+'
      @include fontSWCL(3.33rem, normal, #fff, 3.33rem)
      text-align: center
      user-select: none

  &_controls
    position: absolute
    right: 0
    bottom: 0
    display: flex
    flex-direction: row

  &_add
    width: 2rem
    height: 2rem
    background-color: #fff
    border-radius: 0.33rem
    @include fontSWCL(1.75rem, normal, $yellow, 2rem)
    text-align: center
    cursor: pointer
    user-select: none
    margin-left: 1px
    @include mobile
      display: none

  &_remove
    position: absolute
    right: 2px
    bottom: 2px
    width: 2rem
    height: 2rem
    background-color: #fff
    border-radius: 50%
    cursor: pointer
    user-select: none
    @include fontSWCL(1.75rem, bold, $yellow, 2rem)
    text-align: center

  &-complete
    background-color: $yellow
    align-items: flex-start

    & .partner_tab_title
      width: 100%
      text-align: center
      @include mobile
        display: none

    &::after
      @include mobile
        right: 0.4rem
        top: 0.4rem
        width: auto
        height: 1rem
        content: 'ред.'
        text-decoration: underline
        font-size: 0.83rem
        line-height: 1rem
        -webkit-transform: none
        transform: none
        border-radius: 0

    & .partner_tab_title
      @include fontSWCL(1.5rem, bold, #fff, 1.8rem)
      @include mobile
        font-size: 1.2rem
        font-weight: normal
        line-height: 1.4rem

    & .partner_tab_text
      font-size: 1.2rem
      font-weight: normal
      color: #fff
      line-height: 1.4rem

      &_title
        @include mobile
          font-weight: bold

      &_link
        color: #ff0000
        text-decoration: underline

  &_title
    @include fontSWCL(2rem, normal, #000, 2.4rem)
    @include mobile
      font-size: 1.5rem
      line-height: 1.8rem

  &_short
    @include mobile
      width: 45%

.partner_tab-right ~ .active_tab > .active_tab_body
  right: calc(100% + 1.6rem)
  left: unset
  @include mobile
    right: unset
    left: 0

.partner_item
  margin-top: 1.67rem

  &:first-child
    margin-top: 0
