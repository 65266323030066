.select_profile_form {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;

}
.overlay {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: #00000010;
    z-index: 1;
}


.select_profile_window .field{
    font-size: 16px;
    padding: 10px 5px;
    border: 1px solid #eee;
    text-align: left;
    line-height: 24px;
    margin-bottom: 10px;
    border-radius: 4px;
}
.select_profile_window .field:hover{
    cursor: pointer;
    background: #f0f0f0;
}
.select_profile_window .field.active{
    background: #ffc700;
    color: #fff;
    font-weight: 600;
}

.select_profile_window .field span{
    display: block;
    font-size: 14px;
}

.select_profile_window .title{
    font-size: 18px;
    padding: 5px 10px 20px 0px;
    text-align: left;
    font-weight: 500;
}

.select_profile_window {
    background: #ffffff;
    border-radius: 4px;
    position: absolute;
    z-index: 2;
    width: 98%;
    left: 1%;
    right: 1%;
    margin: 0 auto;
    max-width: 600px;
    min-height: 150px;
    font-size: 18px;
    text-align: center;
    box-shadow: 2px 2px 2px #00000030;
    padding: 15px 20px;
}


.select_profile_window .button{
    width: 200px;
    margin: 0 auto;
    padding: 10px 20px;
    background: #f0f0f0;
    border-radius: 4px;
    font-weight: normal;
    color: #aaa;
    font-size: 15px;
}

.select_profile_window .button_list{
    padding-top: 10px;
}
.select_profile_window .button.active{
    border: 2px solid #ffc700;
    color: #444;
    background: #fff;
    cursor: pointer;
    font-weight: bold;
}
