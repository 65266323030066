.driver_select_container
  background-color: #fff
  width: 100vw
  height: 100vh
  display: flex
  flex-direction: column
  align-items: center
  position: fixed
  left: 0
  top: 0
  z-index: 10
  cursor: default

.driver_select_heading
  width: 100%
  border-bottom: 1px solid $yellow
  @include mobile
    width: calc(100% - 1.33rem)
    margin: 0 0.67rem

.driver_select_title
  @include fontSWCL(3rem, bold, #000, 3.67rem)
  margin-top: 4rem
  margin-bottom: 1.67rem
  text-align: center
  @include mobile
    font-size: 1.5rem
    line-height: 1.8rem
    margin-top: 6rem
    margin-bottom: 0.83rem

.driver_select_body
  width: 100%
  display: flex
  flex-direction: column
  flex-grow: 1
  justify-content: flex-start
  align-items: center
  padding: 0 10%
  @include mobile
    padding: 0 0.83rem

.driver_select_subtitle
  margin-top: 4rem
  @include fontSWCL(2rem, normal, #000, 2.5rem)
  @include mobile
    display: none

.driver_select_tabs
  display: flex
  flex-direction: row
  justify-content: space-around
  align-items: flex-start
  width: 100%
  flex-wrap: wrap
  margin-top: 4.33rem
  @include mobile
    margin-top: 2.33rem

.driver_select_tab
  width: 38%
  margin-top: 1.67rem
  height: 8rem
  padding: 0.4rem 0.8rem
  border: 1px solid $yellow
  border-radius: 0.33rem
  cursor: pointer
  display: flex
  flex-direction: column
  justify-content: center
  align-items: flex-start
  position: relative
  background-color: #fff
  overflow: auto
  @include mobile
    width: 100%
    height: 6.75rem
    justify-content: flex-start
    align-items: center
    padding: 0.4rem 1.6rem

  &:first-child
    @include mobile
      margin-top: 0

  &::after
    @include mobile
      position: absolute
      right: 50%
      bottom: 0.4rem
      -webkit-transform: translateX(50%)
      transform: translateX(50%)
      width: 3.33rem
      height: 3.33rem
      background-color: $yellow
      border-radius: 50%
      content: '+'
      @include fontSWCL(3.33rem, normal, #fff, 3.33rem)
      text-align: center
      user-select: none

  &-complete
    background-color: $yellow
    align-items: flex-start

    & .partner_tab_title
      width: 100%
      text-align: center
      @include mobile
        display: none

    &::after
      @include mobile
        display: none

    & .driver_select_tab_title
      @include fontSWCL(1.5rem, bold, #fff, 1.8rem)
      align-self: center
      @include mobile
        font-size: 1.2rem
        font-weight: normal
        line-height: 1.4rem

    & .driver_select_tab_text
      font-size: 1.2rem
      font-weight: normal
      color: #fff
      line-height: 1.4rem

      &_title
        @include mobile
          font-weight: bold

  &_title
    @include fontSWCL(2rem, normal, #000, 2.4rem)
    text-align: center
    @include mobile
      font-size: 1.5rem
      line-height: 1.8rem

.driver_select_add
  margin-top: 4rem
  @include fontSWCL(1.5rem, normal, $yellow, 1.75rem)
  font-family: Roboto
  text-decoration: underline
  cursor: pointer

.driver_select_back
  margin: 1.67rem auto 4rem auto
  width: 5rem
  @include fontSWCL(1.5rem, normal, $yellow, 1.75rem)
  font-family: Roboto
  text-align: center
  cursor: pointer
  @include mobile
    font-size: 1.2rem
    line-height: 1.33rem
