.vehicle_container
  display: flex
  flex-direction: row
  flex-wrap: wrap
  justify-content: space-between
  align-items: flex-start
  margin: 0 2.5rem
  @include mobile
    margin: 0 0.67rem

  &:first-child
    @include mobile
      margin-top: 1.67rem

  &_item
    margin-top: 1.67rem
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: flex-start
    width: 30%
    @include mobile
      width: 100%
      margin-top: 0.83rem

    &-center
      align-items: center

    &-short
      @include mobile
        width: 47%

    &-auto
      flex-grow: 1
      @include mobile
        flex-grow: 0

.vehicle_subtitle
  margin-top: 1.67rem
  width: 100%
  @include fontSWCL(1.5rem, bold, #000, 1.75rem)
  @include mobile
    text-align: center
    font-size: 1rem
    line-height: 1.25rem
    font-weight: normal

.vehicle_subcontainer
  display: flex
  flex-direction: row
  flex-grow: 1
  justify-content: space-between
  align-self: flex-start
  @include mobile
    width: 100%
    flex-wrap: wrap

  &_item
    width: auto
    align-self: flex-start
    @include mobile
      width: 47%

.vehicle_input
  margin-top: 0.83rem
  padding: 1rem
  width: 20rem
  max-width: 100%
  border: 1px solid #999
  border-radius: 0.33rem
  @include fontSWCL(1.2rem, normal, #000, 1.33rem)
  font-family: Roboto
  @include mobile
    width: 100%
    margin-top: 0.42rem
    padding: 1rem 0.83rem

  &-short
    width: 9rem
    @include mobile
      width: 100%

  &-auto
    width: 100%

  &::placeholder
    color: #bdbdbd

  &-complete
    border: 1px solid $yellow

  &_label
    @include fontSWCL(1.33rem, normal, #000, 1.6rem)
    @include mobile
      font-size: 1.2rem
      line-height: 1.4rem

    &-bold
      font-weight: bold
      @include mobile
        font-weight: normal

.vehicle_select
  padding: 1rem
  @include fontSWCL(1.2rem, normal, #000, 1.33rem)
  font-family: Roboto
  @include mobile
    padding: 1rem 0.83rem

  &-complete
    border-color: $yellow!important

  &_table
    width: 100%

  &_replacement
    margin-top: 1rem
    @include fontSWCL(1.33rem, normal, #000, 1.67rem)

.vehicle_checkbox_container tr
  cursor: pointer

.vehicle_checkbox
  width: 2.5rem
  @include mobile
    width: 3rem

  &-info
    width: 1.67rem
    @include mobile
      width: 1.25rem

    &_sign
      width: 1.25rem
      height: 1.25rem
      border: 1px solid $yellow
      border-radius: 50%
      @include fontSWCL(1rem, normal, $yellow, 1.25rem)
      text-align: center
      @include mobile
        width: 0.83rem
        height: 0.83rem
        font-size: 0.6rem
        line-height: 0.83rem

  &_label
    @include fontSWCL(1.33rem, normal, #000, 1.67rem)
    @include mobile
      font-size: 1rem
      line-height: 1.25rem

  &-info
    @include fontSWCL(1.33rem, normal, #000, 1.67rem)
    @include mobile
      font-size: 0.83rem
      line-height: 1rem
      text-decoration: underline

.vehicle_photo_container
  display: flex
  flex-direction: row
  width: 100%
  justify-content: flex-start
  align-items: flex-start
  @include mobile
    width: 100%
    justify-content: space-around

.vehicle_photo
  margin-top: 0.83rem
  display: flex
  flex-direction: column
  align-items: center
  justify-content: flex-start
  margin-right: 1.33rem
  @include mobile
    margin-right: 0

  &:last-child
    margin-right: 0

  & > input
    display: none

  &_label
    @include fontSWCL(1.33rem, normal, #000, 1.6rem)
    width: 100%
    text-align: center
    @include mobile
      font-size: 1rem
      line-height: 1.25rem

  &_button
    width: 14rem
    max-width: 100%
    height: 3.33rem
    margin: 0.42rem 0
    cursor: pointer
    outline: none
    background: $yellow
    border-radius: 2.08rem
    display: flex
    justify-content: center
    align-items: center
    @include mobile
      width: 8rem

    &_text
      @include fontSWCL(1.2rem, normal, #fff, 1.33rem)
      font-family: Roboto
      text-align: center

    &-complete &_text
      font-weight: bold

    &_snap
      margin-top: 0.83rem
      @include mobile
        width: 12rem
        position: absolute
        bottom: 2.5rem

.vehicle_geo
  @include fontSWCL(1.2rem, normal, $yellow, 1.4rem)
  font-family: Roboto
  text-decoration: underline
  cursor: pointer
  margin-top: 0.33rem
  margin-left: 0.2rem

.vehicle_info
  @include fontSWCL(1rem, normal, #999, 1.2rem)
  font-family: Roboto
  font-style: italic
  margin-top: 0.33rem
  margin-left: 0.2rem

.vehicle_help
  width: 2rem
  height: 2rem
  background: $yellow
  border-radius: 50%
  cursor: pointer
  display: flex
  justify-content: center
  align-items: center
  position: relative

  &_symbol
    @include fontSWCL(1.2rem, bold, #fff, 1.5rem)
    user-select: none

  &_frame
    position: fixed
    left: 50%
    top: 50%
    transform: translate(-50%, -50%)
    border: 1px solid $yellow
    border-radius: 0.33rem
    background: #fff
    padding: 0.5rem
    max-width: calc(100vw - 1rem)
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.2)
    z-index: 100

    & img
      max-width: calc(100vw - 2.5rem)

  &_text
    @include fontSWCL(1.2rem, normal, #000, 1.4rem)
