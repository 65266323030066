@import "../settings/font"
@import "../settings/color"

@mixin fontSWCL($size: 16px, $weight: normal, $color: #000, $line-height: 16px)
  font-family: $font-family
  font-style: normal
  font-size: $size
  color: $color
  font-weight: $weight
  line-height: $line-height

