.bank_container
  display: flex
  flex-direction: row
  flex-wrap: wrap
  justify-content: flex-start
  align-items: flex-start
  margin: 0 2.5rem
  @include mobile
    margin: 0 0.67rem

  &:first-child
    @include mobile
      margin-top: 1.67rem

  &_item
    margin-top: 0.83rem
    margin-right: auto
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: flex-start
    @include mobile
      width: 100%
      margin-top: 0.42rem

    &:last-child
      margin-right: 0

.bank_subcontainer
  display: flex
  flex-direction: row
  @include mobile
    flex-wrap: wrap

  & > input, & > .dadata
    margin-right: 0.83rem

    &:last-child
      margin-right: 0

.bank_dadata
  margin-top: 0.83rem
  min-width: 32rem
  @include mobile
    min-width: auto
    width: 100%
    margin-top: 0.42rem

  &_nomargin
    @include mobile
      margin-right: 0!important

  &-long
    flex-grow: 1
    width: 16rem
    @include mobile
      width: 44%

  &-short
    width: 5rem
    @include mobile
      width: 20%

  &_input
    padding: 1rem
    width: 100%
    border: 1px solid #999
    border-radius: 0.33rem
    @include fontSWCL(1.2rem, normal, #000, 1.33rem)
    font-family: Roboto
    @include mobile
      padding: 1rem 0.83rem

.bank_input
  margin: 0.83rem 0
  padding: 1rem
  width: 20rem
  border: 1px solid #999
  border-radius: 0.33rem
  @include fontSWCL(1.2rem, normal, #000, 1.33rem)
  font-family: Roboto
  @include mobile
    width: 100%
    margin: 0.42rem 0
    padding: 1rem 0.83rem

  &::placeholder
    color: #bdbdbd

  &-short
    width: 5rem
    @include mobile
      width: 20%

  &-complete
    border: 1px solid $yellow

  &_label
    @include fontSWCL(1.33rem, normal, #000, 1.6rem)
    @include mobile
      font-size: 1.2rem
      line-height: 1.4rem
