@mixin mobile
  @media screen and (max-width: 720px)
    @content

@mixin tabletSmall
  @media screen and (max-width: 768px)
    @content

@mixin tablet
  @media screen and (max-width: 1023px)
    @content

@mixin desktopSmall
  @media screen and (max-width: 1279px)
    @content

@mixin desktop
  @media screen and (min-width: 1280px) and (max-width: 1439px)
    @content

@mixin desktopBig
  @media screen and (min-width: 1440px)
    @content

@mixin desktopLarge
  @media screen and (min-width: 1600px)
    @content
